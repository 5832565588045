import {
  getRow,
  getColumn,
  generateRandomState,
  getCellNeighbourCount,
} from "./utils";
import { CELL_SIZE, INTERVAL } from "./defaults";

export default function automaton() {
  let width;
  let height;
  let columns;
  let rows;
  let ctx;
  let cells;
  let timeoutHandler;

  const init = (canvas) => {
    console.log("Initializing automaton");
    width = canvas.offsetWidth;
    height = canvas.offsetHeight;
    canvas.width = width;
    canvas.height = height;
    columns = Math.ceil(width / CELL_SIZE);
    rows = Math.ceil(height / CELL_SIZE);
    ctx = canvas.getContext("2d");
    cells = generateRandomState(rows, columns);

    for (let i = 0; i < cells.length; i++) {
      const cellRow = getRow(i, columns);
      const cellColumn = getColumn(i, columns);
      // ctx.fillStyle = `rgba(47, 76, 160, ${cell ? 0.1 : 0})`;
      ctx.strokeStyle = `rgba(255,255,255,1)`;
      ctx.lineWidth = 0.5;
      ctx.strokeRect(
        (cellColumn - 1) * CELL_SIZE,
        (cellRow - 1) * CELL_SIZE,
        CELL_SIZE,
        CELL_SIZE
      );
    }
  };

  const draw = () => {
    for (let i = 0; i < cells.length; i++) {
      const cell = cells[i];
      const cellRow = getRow(i, columns);
      const cellColumn = getColumn(i, columns);
      ctx.fillStyle = `rgba(47, 76, 160, 0.16)`;
      // ctx.strokeStyle = `rgba(255,255,255,1)`;
      // ctx.lineWidth = 0.5;
      // ctx.strokeRect(
      //   (cellColumn - 1) * CELL_SIZE,
      //   (cellRow - 1) * CELL_SIZE,
      //   CELL_SIZE,
      //   CELL_SIZE
      // );
      if (cell) {
        ctx.fillRect(
          (cellColumn - 1) * CELL_SIZE + 1,
          (cellRow - 1) * CELL_SIZE + 1,
          CELL_SIZE - 2,
          CELL_SIZE - 2
        );
      }
    }
  };

  const clear = () => {
    for (let i = 0; i < cells.length; i++) {
      const cellRow = getRow(i, columns);
      const cellColumn = getColumn(i, columns);
      // ctx.fillStyle = `rgba(47, 76, 160, ${cell ? 0.1 : 0})`;
      // ctx.strokeStyle = `rgba(255,255,255,1)`;
      // ctx.lineWidth = 0.5;
      // ctx.strokeRect(
      //   (cellColumn - 1) * CELL_SIZE,
      //   (cellRow - 1) * CELL_SIZE,
      //   CELL_SIZE,
      //   CELL_SIZE
      // );
      ctx.clearRect(
        (cellColumn - 1) * CELL_SIZE + 1,
        (cellRow - 1) * CELL_SIZE + 1,
        CELL_SIZE - 2,
        CELL_SIZE - 2
      );
    }
  };

  const mutate = (cb) => {
    const newCells = cells.map((cell, i) => {
      const neighbourCount = getCellNeighbourCount(cells, i, rows, columns);
      if (!cell) {
        if (neighbourCount === 3) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (neighbourCount < 2) {
          return 0;
        } else if (neighbourCount > 3) {
          return 0;
        } else {
          return 1;
        }
      }
    });

    cells = [...newCells];
    clear();
    draw();

    cb();
  };

  const run = () => {
    timeoutHandler = window.setTimeout(() => {
      mutate(run);
    }, INTERVAL);
  };

  const start = () => {
    mutate(run);
  };

  const stop = () => {
    if (timeoutHandler) {
      window.clearTimeout(timeoutHandler);
    }
  };

  const activate = (x, y) => {
    const cellRow = Math.floor(y / CELL_SIZE);
    const cellColumn = Math.floor(x / CELL_SIZE);
    // console.log(cellRow, cellColumn);
    // return;
    const cellIndex = cellRow * columns + cellColumn;
    cells[cellIndex] = 1;
    // console.log(cellIndex);
    ctx.clearRect(
      cellColumn * CELL_SIZE + 1,
      cellRow * CELL_SIZE + 1,
      CELL_SIZE - 2,
      CELL_SIZE - 2
    );
    ctx.fillRect(
      cellColumn * CELL_SIZE + 1,
      cellRow * CELL_SIZE + 1,
      CELL_SIZE - 2,
      CELL_SIZE - 2
    );
  };

  return {
    init,
    draw,
    start,
    stop,
    activate,
  };
}
