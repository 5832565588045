export const getColumn = (index, totalColumns) => {
  return (index % totalColumns) + 1;
};

export const getRow = (index, totalColumns) => {
  const cellColumn = getColumn(index, totalColumns);
  const row = (index + 1 - cellColumn) / totalColumns + 1;
  return row;
};

export const getCellNeighbourCoordinates = (
  row,
  column,
  totalRows,
  totalColumns
) => {
  return [
    [row - 1, column - 1],
    [row - 1, column],
    [row - 1, column + 1],
    [row, column - 1],
    [row, column + 1],
    [row + 1, column - 1],
    [row + 1, column],
    [row + 1, column + 1],
  ].filter(
    (rc) =>
      rc[0] > 0 && rc[0] <= totalRows && rc[1] > 0 && rc[1] <= totalColumns
  );
};

export const getIndexFromCoordinates = (row, column, totalColumns) => {
  return (row - 1) * totalColumns + column - 1;
};

export const getCellNeighbourCount = (
  cellArray,
  index,
  totalRows,
  totalColumns
) => {
  const cellColumn = getColumn(index, totalColumns);
  const cellRow = getRow(index, totalColumns);

  const neighbourCellIndeces = getCellNeighbourCoordinates(
    cellRow,
    cellColumn,
    totalRows,
    totalColumns
  ).map((rc) => getIndexFromCoordinates(rc[0], rc[1], totalColumns));

  let liveCellCount = 0;
  neighbourCellIndeces.forEach((n) => {
    if (cellArray[n]) {
      liveCellCount++;
    }
  });

  return liveCellCount;
};

export const generateRandomState = (rows, columns) => {
  return new Array(rows * columns).fill(0).map(() => {
    const rand = Math.random();
    if (rand >= 0.5) {
      return 1;
    } else {
      return 0;
    }
  });
};
