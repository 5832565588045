import "./App.css";
import { useRef, useLayoutEffect, useState } from "react";
import ContactForm from "./ContactForm";

function App({ automaton }) {
  const canvasRef = useRef(null);
  const [contactForm, setContactForm] = useState(false);
  const [careers, setCareers] = useState(false);
  const [about, setAbout] = useState(false);
  // console.log(cells);

  useLayoutEffect(() => {
    if (canvasRef && canvasRef.current) {
      const canvas = canvasRef.current;
      automaton.init(canvas);
      automaton.draw();
      automaton.start();
    }

    return () => {
      automaton.stop();
    };
  }, [canvasRef, automaton]);

  return (
    <div className="Main">
      <div className="Content">
        <div className="header">
          <div
            className="logo"
            onClick={() => {
              setAbout(false);
            }}
          >
            <img src="/logo.svg" alt={""} />
          </div>
        </div>

        <div className="body">
          {about ? (
            <div className="about">
              <div>
                <div className="h3">We make technology</div>
                <div className="h3">that helps your business grow.</div>

                <div className="subheading">
                  Here are some of the services we offer:
                </div>
              </div>
              <div className="text-group">
                <div className="lead-text">
                  <img src={"/dev.svg"} alt={""} className="icon" />
                  Application Design & Development
                </div>
                <div className="body-copy">
                  Whether you have a great new idea for an app/website or you
                  just want to take your existing business online, we can help
                  you envision, design, create and manage your technology.{" "}
                </div>
              </div>

              <div className="text-group">
                <div className="lead-text">
                  <img src={"/ml.svg"} alt={""} className="icon" />
                  Operational Transformation & Machine Learning
                </div>
                <div className="body-copy">
                  At Ket, we work very hard to understand your business and
                  develop tools to help you start digitizing, optimizing and
                  analyzing your sales, marketing, operations & other business
                  processes.
                </div>
              </div>

              <div className="text-group">
                <div className="lead-text">
                  <img src={"/consulting.svg"} alt={""} className="icon" />
                  Consulting
                </div>
                <div className="body-copy">
                  Need advice on how you can best use technology to achieve your
                  business objectives?{" "}
                  <span
                    onClick={() => {
                      setContactForm(true);
                    }}
                    className="link"
                  >
                    Get in touch
                  </span>
                  , we would love to help.
                </div>
              </div>
            </div>
          ) : (
            <div className="hero">
              <div className="primary">Software,</div>
              <div className="primary">Web Development,</div>
              <div className="primary">Design.</div>
              <div className="secondary">
                We like making{" "}
                <div className="thing-container">
                  <span
                    className="text"
                    onClick={() => {
                      setAbout(true);
                    }}
                  >
                    things
                  </span>
                  <img src={"/thing-1.svg"} className="thing-1" alt={""} />
                  <img src={"/thing-2.svg"} className="thing-2" alt={""} />
                  <img src={"/thing-3.svg"} className="thing-5" alt={""} />
                  <img src={"/thing-4.svg"} className="thing-4" alt={""} />
                  <img src={"/thing-5.svg"} className="thing-3" alt={""} />
                  <img src={"/thing-6.svg"} className="thing-6" alt={""} />
                  <img src={"/thing-7.svg"} className="thing-7" alt={""} />
                  <img src={"/thing-8.svg"} className="thing-8" alt={""} />
                </div>
                .
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="Ca"
        onMouseEnter={() => {
          automaton.stop();
        }}
        onMouseLeave={() => {
          automaton.start();
        }}
      >
        <div className="automaton-wrapper">
          {/*cells.map((cell, i) => (
            <Cell key={"cell" + i} index={i} size={CELL_SIZE} flag={cell} />
          ))*/}
          {/*<canvas id="automaton-canvas"></canvas>*/}
          <canvas
            id="automaton-canvas"
            ref={canvasRef}
            onMouseMove={(e) => {
              const rect = canvasRef.current.getBoundingClientRect();
              const x = e.clientX - rect.left;
              const y = e.clientY - rect.top;
              automaton.activate(x, y);
              // console.log(x, y);
            }}
          ></canvas>
        </div>

        <div
          className={
            "contact-form-wrapper hide-mobile " + (contactForm ? "open" : "")
          }
        >
          {contactForm ? (
            <ContactForm setContactForm={setContactForm} />
          ) : (
            <div
              className="head"
              onClick={() => {
                // setShowAutomaton(false);
                setContactForm(true);
              }}
            >
              <div className="text">GET IN TOUCH</div>

              <div className="icon">
                <img src={"/chat.svg"} alt={""} />
              </div>
            </div>
          )}
        </div>

        <div
          className="careers-button hide-mobile"
          onClick={() => {
            setCareers(true);
          }}
        >
          <img src={"/cv.svg"} alt={""} />
          WE'RE HIRING!
        </div>
        <div
          className={"careers-container hide-mobile " + (careers ? "open" : "")}
        >
          <div
            className="hide"
            onClick={() => {
              setCareers(false);
            }}
          >
            <img src="/close.svg" alt={""} />
          </div>
          <div className="primary-text">
            We're looking for talented and driven designers & developers at all
            levels.
          </div>
          <div className="secondary-text">
            Email your resumes to{" "}
            <span className="green">careers@ket.technology</span>
          </div>
        </div>
      </div>

      <div
        className={
          "contact-form-wrapper show-mobile " + (contactForm ? "open" : "")
        }
      >
        {contactForm ? (
          <ContactForm setContactForm={setContactForm} />
        ) : (
          <div
            className="head"
            onClick={() => {
              // setShowAutomaton(false);
              setContactForm(true);
            }}
          >
            <div className="text">GET IN TOUCH</div>

            <div className="icon">
              <img src={"/chat.svg"} alt={""} />
            </div>
          </div>
        )}
      </div>

      <div
        className="careers-button show-mobile"
        onClick={() => {
          setCareers(true);
        }}
      >
        <img src={"/cv.svg"} alt={""} />
        WE'RE HIRING!
      </div>

      <div
        className={"careers-container show-mobile " + (careers ? "open" : "")}
      >
        <div
          className="hide"
          onClick={() => {
            setCareers(false);
          }}
        >
          <img src="/close.svg" alt={""} />
        </div>
        <div className="primary-text">
          We're looking for talented and driven designers & developers at all
          levels.
        </div>
        <div className="secondary-text">
          Email your resumes to{" "}
          <span className="green">careers@ket.technology</span>
        </div>
      </div>
    </div>
  );
}

export default App;
