import { useState } from "react";
import "./ContactForm.css";
import useMutation from "./useMutation";

function ContactForm({ setContactForm }) {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [requirement, setRequirement] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [enquire, { loading }] = useMutation(
    "https://ket-express-sojwr.ondigitalocean.app/enquire",
    {
      onSuccess: (data) => {
        console.log("Response Data:", data);
        localStorage.setItem("enquired", "true");
        setSubmitted(true);
      },
      onError: (message) => {
        console.log("Error:", message);
        setError(message);
      },
    }
  );

  if (submitted) {
    return (
      <div className="form-submitted">
        <div
          className="close"
          onClick={() => {
            setContactForm(false);
          }}
        >
          <img src={"/close.svg"} alt={""} />
        </div>
        <img src="/smile.svg" alt={""} />
        <div className="primary-text">All done, thanks!</div>
        <div className="secondary-text">
          A representative will be in touch with you shortly.
        </div>
      </div>
    );
  }

  return (
    <form
      className="contact-form"
      onSubmit={(e) => {
        e.preventDefault();
        if (loading) {
          return;
        }
        enquire({
          name,
          location,
          email,
          phone,
          requirement,
        });
      }}
    >
      <div
        className="close"
        onClick={() => {
          setContactForm(false);
        }}
      >
        <img src={"/close.svg"} alt={""} />
      </div>
      <div className="primary-text">Get in touch</div>

      <div className="secondary-text">
        Please fill out this quick form and we will be in touch with lightning
        speed.
      </div>

      <div className="row">
        <div className="input-group">
          <input
            type="text"
            required="required"
            name="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <label htmlFor="name">Name</label>
        </div>

        <div className="input-group">
          <input
            type="text"
            required="required"
            name="location"
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
            }}
          />
          <label htmlFor="location">Location</label>
        </div>
      </div>

      <div className="row">
        <div className="input-group">
          <input
            type="text"
            required="required"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <label htmlFor="email">Email</label>
        </div>

        <div className="input-group">
          <input
            type="text"
            required="required"
            name="phone"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          <label htmlFor="phone">Phone</label>
        </div>
      </div>

      <div className="input-group">
        <textarea
          type="text"
          name="requirement"
          value={requirement}
          onChange={(e) => {
            setRequirement(e.target.value);
          }}
        />
        {!requirement && (
          <label htmlFor="requirement">
            What would you like us to help you with?
          </label>
        )}
      </div>
      <button type="submit">{loading ? "..." : "SUBMIT"}</button>
      <span className="error">{error}</span>
    </form>
  );
}

export default ContactForm;
